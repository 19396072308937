import moment from 'moment';
import { guid } from '../afw/js/common';
const oem = {
  t_component: {
    imports: (self) => {
      self.datasource = JSON.parse(self.datasource || "{}");
      self.props = JSON.parse(self.props || "{}");
      self.component_id = self.component_id === 0 ? guid() : self.component_id;
    },
    exports: (self) => {
      return ({
        props: JSON.stringify(self.props),
        datasource: JSON.stringify(self.datasource),
        layout_id: typeof self.layout_id === 'string' ? 0 : self.layout_id
      })
    }
  },
  t_design_build_detail: {
    exports: (self) => {
      return ({
        detail_id: typeof self.detail_id === 'string' ? 0 : self.detail_id
      })
    }
  },
  t_home_gallery: {
    imports: (self) => {
      self.ad_config = JSON.parse(self.ad_config || "{}");
    },
    exports: (self) => {
      return ({
        ad_config: JSON.stringify(self.ad_config)
      })
    }
  },
  t_file: {
    imports: (self) => {
      let tags = self.tags.split(",")
      self.tags = tags.length && tags[0] !== "" ? tags : null;
      self.delete_ind = typeof self.delete_ind === 'boolean' ? self.delete_ind : false;
    },
    exports: (self) => {
      return ({
        tags: self.tags.join(",")
      })
    }
  },
  t_page: {
    imports: (self) => {
      let page_tags = self.page_tags && self.page_tags.split(",")
      self.page_tags = page_tags && page_tags.length && page_tags[0] !== "" ? page_tags : null;
      self.page_prop = JSON.parse(self.page_prop || "{}");
    },
    exports: (self) => {
      return ({
        page_prop: JSON.stringify(self.page_prop),
        page_tags: self.page_tags && self.page_tags.join(",")
      })
    }
  },
  t_page_layout: {
    imports: (self) => {
      self.col = self.col ? self.col : 1;
      self.row = self.row ? self.row : 1;
    }
  },
  t_legal_ad: {
    imports: (self) => {
      self.remove_date = self.remove_date ? self.remove_date : moment(self.remove_date);
      self.release_date = self.release_date ? self.release_date : moment(self.release_date);
    },
    exports: (self) => {
      return ({
        release_date: moment(self.release_date).format(),
        remove_date: moment(self.remove_date).format()
      })
    }
  },
}
export default oem